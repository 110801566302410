import { NativeQRScannerMessageNames } from 'Component/NativeQRCodeScanner/QRCodeScanner.config';
import { getStore } from 'Util/Store';

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';

/** @namespace Scandipwa/Util/Device/getIsMobile */
export const getIsMobile = () => {
    const { isMobile } = getStore().getState().ConfigReducer.device;

    return isMobile;
};

/** @namespace Scandipwa/Util/Device/getPropByDevice */
export const getPropByDevice = (props, data) => {
    const isMobile = getIsMobile();
    const device = isMobile ? MOBILE : DESKTOP;
    const propData = props[data][device];

    return propData;
};

export const DeviceType = {
    IOS: 'IOS',
    ANDROID: 'ANDROID',
    // TODO: extend with other types as needed
    IOS_WKWEBVIEW: 'IOS_WKWEBVIEW',
    ANDROID_WEBVIEW: 'ANDROID_WEBVIEW',
    OTHER_MOBILE: 'OTHER_MOBILE',
    MOBILE_PWA_IOS_APP: 'MOBILE_PWA_IOS_APP',
    MOBILE_PWA_ANDROID_APP: 'MOBILE_PWA_ANDROID_APP',
    DESKTOP_PWA: 'DESKTOP_PWA',
    Default: 'Default'
};

/** @namespace Scandipwa/Util/Device/getDeviceType */
export const getDeviceType = () => {
    const isIOS = /iP(ad|od|hone)/i.test(navigator.userAgent);
    const isWKWebView = window?.webkit?.messageHandlers[NativeQRScannerMessageNames.startScanner];
    const isAndroidWebview = window.AndroidManager?.initialize;
    const isMobile = getIsMobile();
    const isPWA = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    const isAndroid = /android/i.test(navigator.userAgent);

    if (isPWA && isIOS) {
        return DeviceType.MOBILE_PWA_IOS_APP;
    }

    if (isPWA && isAndroid) {
        return DeviceType.MOBILE_PWA_ANDROID_APP;
    }

    if (isPWA && !isMobile) {
        return DeviceType.DESKTOP_PWA;
    }

    if (isWKWebView) {
        return DeviceType.IOS_WKWEBVIEW;
    }

    if (isAndroidWebview) {
        return DeviceType.ANDROID_WEBVIEW;
    }

    if (isIOS) {
        return DeviceType.IOS;
    }

    if (isAndroid) {
        return DeviceType.ANDROID;
    }

    if (isMobile) {
        return DeviceType.OTHER_MOBILE;
    }

    return DeviceType.Default;
};
