import { MILLISECONDS_IN_SECOND } from 'Util/Auth';

export const MAX_TRY_COUNT = 10;
export const TRY_INTERVAL_MS = 150;

/**
 * If you are using this function, this means you have achieved level of god programming
 * @namespace Scandipwa/Util/Misc/waitForCallback */
export const waitForCallback = async (callback, n = 0) => {
    if (n === MAX_TRY_COUNT) {
        return false;
    }

    const val = callback();

    if (val) {
        return true;
    }

    await new Promise((res) => setTimeout(res, TRY_INTERVAL_MS));

    return waitForCallback(callback, n + 1);
};

/** @namespace Scandipwa/Util/Misc/sleep */
export const sleep = (seconds) => new Promise((resolve) => setTimeout(
    resolve, seconds * MILLISECONDS_IN_SECOND
));

/** @namespace Scandipwa/Util/Misc/isEmpty */
export const isEmpty = (value) => {
    switch (typeof value) {
    case 'number':
    case 'bigint':
        return false;

    case 'object':
        if (Array.isArray(value)) {
            return !value.length;
        }

        return !value;
    default:
        return !value;
    }
};
