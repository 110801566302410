/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    DeviceType,
    getDeviceType
} from 'Util/Device';
import {
    debugNative,
    resolveFromNative
} from 'Util/Native';

// 2000 * 150ms interval = 5 minutes. Wait for status to be enabled before expiring callback
export const N_TRIES = 2000;

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one, fp/no-let
let isAlreadyWaiting = false;

export const TRACKING_TRANSPARENCY_VALUES = {
    Auhtorized: 'Auhtorized',
    Unauthorized: 'Unauthorized',
    Denied: 'Denied',
    Notdetermined: 'Notdetermined',
    Restricted: 'Restricted',
    Unknown: 'Unknown'
};

/** @namespace Scandiweb/Gtm/Util/WaitForAppTrackingTransparencyFrameworkStatus/waitForAppTrackingTransparencyFrameworkStatus */
export const waitForAppTrackingTransparencyFrameworkStatus = async () => {
    // AppTrackingTransparencyFramework is Apple IOS app feature. Return true for all other devices.
    if (getDeviceType() !== DeviceType.IOS_WKWEBVIEW) {
        return true;
    }

    if (isAlreadyWaiting) {
        return false;
    }

    isAlreadyWaiting = true;

    // Resolving asyncronously, to make sure to always receive latest true status for AppTrackingTransparency
    const result = await resolveFromNative(({ toResolve: 'AppTrackingTransparencyFrameworkStatus' }));

    return result === TRACKING_TRANSPARENCY_VALUES.Auhtorized;
};
