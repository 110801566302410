/* eslint-disable no-unreachable */
import { NativeQRScannerMessageNames } from 'Component/NativeQRCodeScanner/QRCodeScanner.config';
import { waitForCallback } from 'Util/Misc';

/** @namespace Scandipwa/Util/Native/isNative */
export const isNative = (isOnly) => {
    if (isOnly) {
        return window?.webkit?.messageHandlers[NativeQRScannerMessageNames.startScanner];
    }

    const isNative = window?.webkit?.messageHandlers[NativeQRScannerMessageNames.startScanner] || window.BarcodeManager;

    return !!isNative;
};

/** @namespace Scandipwa/Util/Native/sendToNative */
export const sendToNative = ({ command, data }) => {
    // vvv If IOS
    if (window?.webkit?.messageHandlers) {
        window.webkit.messageHandlers[command].postMessage(data);
    }

    // vvv If Android
    if (window.BarcodeManager?.handleMessage) {
        window.BarcodeManager.handleMessage(data);
    }
};

/** @namespace Scandipwa/Util/Native/debugNative */
export const debugNative = (data) => {
    const newData = JSON.parse(JSON.stringify(data, (key, value) => (value === undefined ? 'WAS UNDEFINED' : value)));

    sendToNative(({
        command: 'Debug',
        data: JSON.stringify(newData)
    }));
};

/** @namespace Scandipwa/Util/Native/sendToNativeAndResolve */
export const sendToNativeAndResolve = async ({ command, data }) => {
    sendToNative({
        command,
        data
    });

    const key = `${command}_res`;

    const result = await waitForCallback(() => window[key]);

    if (result) {
        return window[key];
    }

    return null;
};

/** @namespace Scandipwa/Util/Native/resolveFromNative */
export const resolveFromNative = async ({ toResolve }) => sendToNativeAndResolve({ command: toResolve, data: null });

/** @namespace Scandipwa/Util/Native/connectNative */
export function connectNative(NativeComponent, isOnly = false) {
    // vvv Need to render a functional component, because if returned null React will render invalid <null> component
    return (props) => {
        if (isNative(isOnly)) {
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            return <NativeComponent { ...props } />;
        }

        return null;
    };
}

/** @namespace Scandipwa/Util/Native/initialize */
export const initialize = () => {
    // vvv If IOS
    if (window?.webkit?.messageHandlers) {
        window.webkit.messageHandlers.initialize.postMessage();
    }

    // vvv If Android
    if (window.AndroidManager?.initialize) {
        window.AndroidManager.initialize();
    }
};
